<template>
	<edit-template class="role-edit" ref="edit" @confirm="save('form')" @cancel="open" :cancelRemind="false" confirmText="保存" cancelText="取消">
		
    <!-- 大表单 -->
    <div class="form-body">
      <el-form ref="form" :model="form" :rules="rules" label-width="150rem" style="width: 500rem">
        <el-form-item label="班级名称" prop="class_name">
          <el-input v-model="form.class_name" placeholder="请输入班级名称"></el-input>
        </el-form-item>

        <el-form-item label="年级" prop="grade_id">
          <el-select :popper-append-to-body="false" v-model="form.grade_id" placeholder="请选择年级">
            <el-option v-for="item in listData" :key="item.value" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="科目" prop="subject">
          <!-- <el-select
            :popper-append-to-body="false"
            v-model="form.fist_subject_id"
            placeholder="请选择一级科目大类"
            @change="fistSubjectIdChange"
          >
            <el-option v-for="item in listDataTo" :key="item.id" :label="item.subject_name" :value="item.id"> </el-option>
          </el-select> -->
          <el-cascader v-model="form.subject" :options="listDataTo" @change="fistSubjectIdChange"></el-cascader>
        </el-form-item>

        <!-- <el-form-item label="二级科目小类" prop="subject_id">
          <el-select :popper-append-to-body="false" v-model="form.subject_id" placeholder="请选择二级科目大类">
            <el-option v-for="item in listDataToGo" :key="item.id" :label="item.subject_name" :value="item.id"> </el-option>
          </el-select>
        </el-form-item> -->

        <el-form-item label="层级" prop="hierarchy">
          <!-- <el-radio v-model="form.hierarchy" label="A">A级</el-radio>
          <el-radio v-model="form.hierarchy" label="B">B级</el-radio>
          <el-radio v-model="form.hierarchy" label="C">C级</el-radio>
          <el-radio v-model="form.hierarchy" label="D">D级</el-radio>
          <el-radio v-model="form.hierarchy" label="艺体">艺体</el-radio> -->
          <el-select v-model="form.hierarchy" placeholder="请选择层级">
            <el-option v-for="(item, index) of listDataToHierarchy" :key="index" :label="item" :value="index"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="人数限制" required="">
          <div style="display: flex">
            <el-form-item prop="min_number">
              <el-input-number v-model="form.min_number" :min="0" :max="9999" :controls="false" @change="minHandle" :step="1" step-strictly="" label="请输入"></el-input-number>
            </el-form-item>
            <span style="margin: 0 10rem"> 至 </span>
            <el-form-item prop="max_number">
              <el-input-number v-model="form.max_number" :min="0" :max="9999" :controls="false" @change="maxHandle" :step="1" step-strictly="" label="请输入"></el-input-number>
            </el-form-item>
          </div>
        </el-form-item>

        <el-form-item label="应用校区" prop="school_id">
          <el-select :popper-append-to-body="false" v-model="form.school_id" placeholder="请选择校区" @change="resetTeacher">
            <el-option v-for="(item, index) in schoolList" :key="item" :label="item" :value="index"></el-option>
          </el-select>
        </el-form-item>

        <template v-if="form.hierarchy == 6">
          <el-form-item v-for="(item,index) in teacherConfig" :label="item.label" :prop="'peiyou_teacher[' + index + '].teacher_id'" :rules="item.rules">
            <el-select v-model="form.peiyou_teacher[index].teacher_id" filterable="" remote="" clearable="" reserve-keyword="" :placeholder="item.placeholder" @change="selectChange($event,index)" :remote-method="querySearchAsync" :popper-append-to-body="true" :loading="loading">
              <el-option v-for="_item in teacher_options" :key="_item.teacher_id" :label="_item.teacher_name" :value="_item.teacher_id" :disabled="_item.disabled">
              </el-option>
            </el-select>
          </el-form-item>
        </template>
      </el-form>
    </div>

    <!-- 底部按钮 -->
    
  
</edit-template>
</template>

<script>
import {getGrade, getSubject, subjectArr, teachingAdd} from '@/api/account-management'

export default {
	_config:{"route":{"path":"add","meta":{"title":"新增"}}},
  data() {
    return {
      checkData: [],
      form: {
        subject_id: '',
        teacher_id: 0,
        max_number: 0,
        min_number: 0,
        peiyou_teacher: [{teacher_id: ''}, {teacher_id: ''}, {teacher_id: ''}, {teacher_id: ''}, {teacher_id: ''}]
      },
      teacher_options: [],
      loading: false,
      teacherConfig: [
        {
          label: '教学老师',
          rules: [{required: true, message: '请输入教学老师', trigger: 'change'}],
          placeholder: '请输入教学老师'
        },
        {label: '助教1', placeholder: '请输入助教老师1'},
        {label: '助教2', placeholder: '请输入助教老师2'},
        {label: '助教3', placeholder: '请输入助教老师3'},
        {label: '助教4', placeholder: '请输入助教老师4'},
      ],

      hierarchyArr: {},
      listData: [],
      listDataTo: [],
      schoolList: [],
      listDataToGo: [],
      listDataToHierarchy: {},
      rules: {
        class_name: [
          {required: true, message: '请输入班级名称', trigger: 'blur'},
          {min: 1, max: 50, message: '字数限制在50字以内', trigger: 'blur'}
        ],
        grade_id: [{required: true, message: '请选择年级', trigger: 'change'}],
        subject: [{required: true, message: '请选择科目', trigger: 'change'}],
        hierarchy: [{required: true, message: '请选择层级', trigger: 'change'}],
        min_number: [{required: true, message: '请输入最小人数', trigger: 'blur'}],
        max_number: [{required: true, message: '请输入最大人数', trigger: 'blur'}],
        school_id: [{required: true, message: '请选择应用校区', trigger: 'change'}],
        subject_id: [{required: true, message: '请选择对应科目类', trigger: 'change'}],
      }
    }
  },

  methods: {
    save(form) {
      this.$refs[form].validate(valid => {
        if (valid) {
          if (this.form.min_number < 0 || this.form.max_number < 0) {
            this.$message.error('人数不能为负数')
          } else {
            if (this.form.min_number > this.form.max_number) {
              this.$message.error('最大人数不能小于最小人数')
            } else {
              let _arr = [];
              if(this.form.hierarchy == 6){
                let arr = JSON.parse(JSON.stringify(this.form.peiyou_teacher));
                _arr = arr.filter(item => {
                  return !!item.teacher_id
                })
              }
              let params = {
                ...this.form,
                peiyou_teacher: _arr
              }
              teachingAdd(params).then(res => {
                if (res.data.error.errorCode != 0) {
                  this.$message.error(res.data.error.errorUserMsg)
                } else {
                  this.$message({
                    message: '注册成功',
                    type: 'success',
                    onClose:() => {
                      this.$store.commit('setPage', 1)
                      this.$router.back()
                    }
                  })
                }
              })
            }
          }
        }
      })
    },
    open() {
      this.$confirm('是否取消新增', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$router.back()
      })
    },
    fistSubjectIdChange(val) {
      // this.form.pid = val
      this.resetTeacher()
      this.form.subject_id = ''
      getSubject(val).then(res => {
        this.listDataToGo = res.data.data
      })
    },

    resetTeacher() {
      this.form.peiyou_teacher = [{teacher_id: ''}, {teacher_id: ''}, {teacher_id: ''}, {teacher_id: ''}, {teacher_id: ''}]
      this.teacher_options = []
      this.$refs.form.clearValidate('peiyou_teacher')
    },

    querySearchAsync(str) {
      this.loading = true
      let params = {
        school_id: this.form.school_id || '',
        fist_subject_id: this.form.subject ? this.form.subject[0] : '',
        subject_id: this.form.subject ? this.form.subject[1] : '',
        clerk_name: str,
      }
      this.$_axios2('api/common/public/get-teacher-list', {params}).then(res => {
        let arr = res.data.data ? res.data.data : [];
        this.teacher_options = this.checkFilter(arr)
      }).finally(() => {
        this.loading = false
      })
    },

    selectChange(id, index) {
      this.$set(this.form.peiyou_teacher, index, {
        teacher_id: id,
        teacher_type: index === 0 ? 1 : 0
      })
      this.teacher_options = this.checkFilter()
    },

    checkFilter(arr = this.teacher_options) {
      let _arr = JSON.parse(JSON.stringify(arr))
      let __arr = this.form.peiyou_teacher.map(item => item.teacher_id)
      return _arr.map(item => {
        return {
          ...item,
          disabled: __arr.includes(item.teacher_id)
        }
      })
    },

    minHandle(e){
      let max = this.form.max_number;
      if(e > max) this.form.max_number = e
    },

    maxHandle(e){
      let min = this.form.min_number;
      if(e < min) this.form.min_number = e
    }
  },

  created() {
    this.$_axios.get('site/curriculum').then(res => {
      this.hierarchyArr = res.data.data
      this.listDataToHierarchy = res.data.data

    })
    getGrade().then(res => {
      this.listData = res.data.data
    })
    subjectArr().then(res => {
      this.listDataTo = JSON.parse(
          JSON.stringify(res.data.data)
              .replace(/id/g, 'value')
              .replace(/subject_name/g, 'label')
              .replace(/child/g, 'children')
      )
    })
    // this.schoolList = this.$store.getters.userInfo.school_arr
    this.$utils.commonApi.getSchoolList().then(res => {
      this.schoolList = res
    })

  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-input--small .el-input__inner {
  width: 300rem;
  height: 40rem;
  color: #999;
}

::v-deep .el-input-number {
  width: 86rem !important;
}
</style>
